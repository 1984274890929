/* LongCenteredForm.css */

.centered-form {
    top:50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust height as needed */
    position: relative;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  label {
    margin-right: 10px; /* Adjust spacing between label and input */
  }
  
  input {
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px; /* Adjust spacing between input and button */
    width: 1000px;
  }
  
  button {
    padding: 12px 20px;
    font-size: 18px;
    cursor: pointer;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
  }
  
  button:hover {
    background-color: #2980b9;
  }
  
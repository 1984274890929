/* ErrorPopup.css */

.error-popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(90deg, rgb(255, 0, 0) 0%, rgb(255, 13, 13) 45%, #e80202 75%);
  color: #fff;
  padding: 200px; /* Adjust padding to increase size */
  border-radius: 10px; /* Adjust border radius for rounded corners */
  text-align: center;
  opacity: 0;
  animation: show-new .7s ease-in-out;
  z-index: 1000;
}

.error-popup.visible {
  display: block;
  opacity: 1;
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Saira';
  font-size: 15px;
}

button {
  position: relative;
  height: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(255, 122, 122);
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  transition: background .3s ease-in-out;
  width: 100%;
}

.hide {
  animation: hide-new .7s ease-in-out;
}

.error-popup button:hover {
  background: rgb(94, 76, 76);
}

@keyframes show-new {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.0);
  }
}

@keyframes hide-new {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0);
  }
}



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  min-height: 100vh;
  font-family: 'Barlow', sans-serif;
  color: #434343;
  background: linear-gradient(90deg, 
      #f3f4f6 0%, 
      #f3f4f6 50%, 
      #f9fafb 50%, 
      #f9fafb 100%
  );
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 1rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo-container {
  text-align: center;
  margin-bottom: 5rem;
}

.logo {
  width: 300px;
  height: auto;
  object-fit: contain;
}

.cards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
}

@media (min-width: 768px) {
  .cards {
      grid-template-columns: 1fr 1fr;
  }
}

.card {
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}

.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.card img {
  width: 30rem;
  height: 18rem;
  object-fit: contain;
  margin-bottom: 1.5rem;
}

.card h2 {

  font-weight: bold;
  font-size: 2.5rem;
  color: #1C5DBB;
  margin-bottom: 0.75rem;
  
}

.card p {
  font-family: 'Barlow', sans-serif;
  font-size: 1rem;
  color: #434343;
  margin-bottom: 2rem;
}

button {
  width: 100%;
  padding: 0.75rem 1.5rem;
  background-color: white;
  color: #434343;
  border: 2px solid #434343;
  border-radius: 0.5rem;
  font-family: 'Barlow', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  background-color: rgb(22, 73, 153);
  border-color: rgb(22, 73, 153);
  color: white;
}

@media (max-width: 767px) {
  body {
      background: linear-gradient(180deg, 
          #f3f4f6 0%, 
          #f3f4f6 50%, 
          #f9fafb 50%, 
          #f9fafb 100%
      );
  }
}
:root {
  --hover-width: 75%;
  --other-width: 25%;
  --speed: 3000ms; /* Adjust speed as needed */
}

.container {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.split {
  flex: 1; /* Equal width initially */
  transition: flex var(--speed) ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  
}

.left {
  background: linear-gradient(270deg, #085fff 0%, #293a58 45%, #1e1e1f 75%); /* Blue, adjust as needed */
  cursor: default;
}

.right {
  background: linear-gradient(270deg, #1e1e1f 0%, #5e287d 45%, #8d38bf 75%); /* Green, adjust as needed */
  cursor: default;
}

.centered {
  text-align: center;
}

.hover-left .left, .hover-right .right {
  flex: var(--hover-width);
  background-color: rgba(0, 0, 0, 0.7); 
}

.hover-left .right, .hover-right .left {
  flex: var(--other-width);
  background-color: rgba(0, 0, 0, 0.7); 
}

h1, p {
  color: #fff; /* Adjust text color as needed */
}

/* Add your existing styles below */
.customButton {
  margin-top: 20px;
  padding: 10px 20px;
  border: 2px solid #fff; /* White border */
  background: transparent;
  color: #fff; /* White text */
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.customButton:hover {
  background-color: #fff; /* White background on hover */
  color: #333; /* Dark text on hover */
}
.container {
  position: relative;
  /* Other styles */
}

.image-wrapper {
  position: absolute;
  top: 50%;
  /* Initially centered, assuming the container doesn't start with a hover state */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px; /* Adjust based on your image and circle size */
  height: 150px;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  transition: left var(--speed) ease-in-out; /* Use the same speed as your split hover effect */
  z-index: 2;
}

